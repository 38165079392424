<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="mod-translation"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "mod-translations",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/mod-translation",
      deleteUrl: "api/admin/mod-translation/delete/",

      editPage: "/admin-dashboard/mod-translation/",
      title: "Mod translations",
      columns: [
        {
          name: "Id",
          value: "id",
        },
        {
          name: "Date",
          value: "createdDate",
          processor: function (dt) {
            let lang = localStorage.getItem("lang")
              ? localStorage.getItem("lang")
              : "en";

            const formatter = new Intl.DateTimeFormat(lang, {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            });
            return formatter.format(new Date(dt));
          },
        },
        {
          name: "Mod Name",
          value: "modName",
        },
        {
          name: "Contact",
          value: "contact",
        },
        {
          name: "IP",
          value: "ipAddress",
        },
      ],
    };
  },

  methods: {},
});
</script>
